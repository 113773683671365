<template>
  <div class="form-elements">
    <div class="va-row">
      <div class="flex md12">
        <vuestic-widget :headerText="'Alterar Senha' | translate">
          <form>
            <div class="flex md12">
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="simple-input"
                    type="password"
                    required="required"
                    v-model="novaSenha.old_password"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Senha Antiga</label>
                  <i class="bar"></i>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="simple-input"
                    type="password"
                    required="required"
                    v-model="novaSenha.new_password1"
                    @keyup="validando"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Nova Senha</label>
                  <i
                    class="bar"
                    v-bind:class="{is_valid : valido}"
                  ></i>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="simple-input"
                    type="password"
                    required="required"
                    v-model="novaSenha.new_password2"
                    @keyup="validando"
                  >
                  <label
                    class="control-label"
                    for="simple-input"
                  >Confirma Senha</label>
                  <i
                    class="bar"
                    :class="{is_valid : valido, not_valid: isvalid}"
                  ></i>
                </div>
              </div>
            </div>
          </form>
          <div class="butoes">
            <button
              class="btn btn-pale btn-micro"
              @click="$router.push({name: 'dashboard'})"
            >
              Voltar
            </button>
            <button class="btn btn-info btn-micro">Altera Senha</button>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { error } from 'util';

export default {
  data() {
    return {
      novaSenha: {
        old_password: '',
        new_password1: '',
        new_password2: '',
      },
      erros: [],
      valido: false,
      isvalid: false,
    };
  },
  created() {
    this.validando();
  },
  methods: {
    mudarSenha() {
      this.$axios.post('/rest-auth/password/change/', this.novaSenha).then((res) => {
        this.situacao = res.data;
      }).then(() => {
        this.$router.push('/');
      })
        .catch((err) => {
          this.erros = err.response.data;
        });
    },
    validando() {
      if (this.novaSenha.new_password1 === this.novaSenha.new_password2) {
        this.valido = !this.valido;
        this.isvalid = !this.isvalid;
      }
      if (this.novaSenha.new_password1 && this.novaSenha.new_password2) {
        this.valido = !this.valido;
        this.isvalid = !this.isvalid;
      } else if (this.novaSenha.new_password1 !== this.novaSenha.new_password2) {
        this.isvalid = true;
        this.valido = false;
      }
      if (this.novaSenha.new_password2) {
        this.isvalid = true;
        this.valido = false;
      } else if (!this.novaSenha.new_password1 && !this.novaSenha.new_password2) {
        this.isvalid = false;
        this.valido = false;
      }
    },
  },
};
</script>
<style  lang="scss">
.form-group input {
  margin: 10px 0;
}
.is_valid {
  color: green !important;
  border-color: green !important;
  &::before {
    content: '';
    background: green !important;
  }
}
.not_valid {
  border-color: red !important;
  &::before {
    content: '';
    background: red !important;
  }
}
</style>
